
import ValidationMixin from "@/mixins/validation";
import {
  SignInTypes,
  UpdatePasswordPayload,
} from "@/store/modules/auth/auth.types";
import { SystemUser, UserTypes } from "@/store/modules/user/user.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const UserX = namespace("User");
const AuthX = namespace(SignInTypes.MODULE);

@Component({
  components: {
    DepartmentSelect: () =>
      import("@/components/department/DepartmentSelect.vue"),
    RoleSelect: () => import("@/components/role/RoleSelect.vue"),
  },
})
export default class ProfileView extends Mixins(ValidationMixin) {
  @AuthX.Action(SignInTypes.UPDATE_PASSWORD)
  public updateUserPassword!: (payload: UpdatePasswordPayload) => void;

  @UserX.Action(UserTypes.LOAD_USER_PROFILE)
  public getUserProfile!: () => void;

  @AuthX.State(SignInTypes.UPDATE_PASSWORD_STATE)
  public updatePassword!: DefaultState;

  @UserX.State(UserTypes.UPDATE_USER_STATE)
  public updateUser!: DefaultState;

  @UserX.State(UserTypes.USER_PROFILE)
  public user!: SystemUser;

  public passwordChange: PasswordChange = {
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  };

  get confirmPasswordRules(): unknown[] {
    return [
      (v: string): boolean | string => !!v || "Password confirmation required",
      (v: string): boolean | string =>
        v === this.passwordChange.NewPassword || "Passwords donot match.",
    ];
  }

  @Ref("form") public formRef!: Validator;
  @Ref("passwordForm") public passwordFormRef!: Validator;

  @UserX.Action(UserTypes.UPDATE_USER)
  public saveUser!: (user: SystemUser) => Promise<void>;

  async updateUserInDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveUser(this.user);
    }
  }

  async updateUserPasswordDb(): Promise<void> {
    await this.passwordFormRef.validate();

    if (this.isPasswordValid) {
      try {
        await this.updateUserPassword({
          oldPassword: this.passwordChange.OldPassword,
          newPassword: this.passwordChange.NewPassword,
        });

        await this.passwordFormRef.reset();
      } catch (e) {
        console.log(e);
      }
    }
  }

  public isValid = false;
  public isPasswordValid = false;

  mounted(): void {
    this.getUserProfile();
  }
}

type PasswordChange = {
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
};
